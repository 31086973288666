import './App.css';

import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './pages/home/Home';
import Citizen from './pages/citizen/Citizen';
import Contact from './pages/contact/Contact';
import Gouvernment from './pages/gouvernment/Gouvernment';
import Constitution from './pages/constitution/Constitution';
import Layout from './pages/layout/Layout';
import NoPage from './pages/noPage/NoPage';

const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />}></Route>
          <Route path="citizenship" element={<Citizen />}></Route>
          <Route path="constitution" element={<Constitution />}></Route>
          <Route path="gouvernment" element={<Gouvernment />}></Route>
          <Route path="contact" element={<Contact />}></Route>
          <Route path="*" element={<NoPage />}></Route>

        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
