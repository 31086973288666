import axios from "axios";

// const baseUrl = "http://localhost:5000/api/citizens";
// const baseUrl = "https://sabentopia-2.onrender.com/api/citizens";
const baseUrl = "api/citizens";

const submitNewCitizen = (newCitizen) => {
    return axios.post(`${baseUrl}/submit`, newCitizen).then(response => response.data);
}

export default { submitNewCitizen }