import { useState } from "react";
import Footer from "../footer/Footer";
import "./Citizenship.css";
import citizenshipService from "../../services/citizen";
import alertService from "../../services/alert";

const Citizenship = () => {

    const [name, setName] = useState("");
    const [familyName, setFamilyName] = useState("");
    const [mail, setMail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthDate, setBirthDate] = useState("");
    const [otherNat, setOtherNat] = useState([]);

    const [capitalRes, setCapitalRes] = useState("");
    const [langRes, setLangRes] = useState("");
    const [religRes, setReligRes] = useState("");

    const [kingRes, setKingRes] = useState("");
    const [ageRes, setAgeRes] = useState("");
    const [headGouvRes, setHeadGouvRes] = useState("");
    const [parlMemRes, setParlMemRes] = useState("");
    const [nationTextRes, setNationTextRes] = useState("");

    const [motivation, setMotivation] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!name || !familyName || !mail || !phoneNumber || !birthDate || !otherNat || !capitalRes || !langRes || !religRes || !kingRes || !ageRes || !headGouvRes || !parlMemRes || !nationTextRes || !motivation) {
            alertService.popupHandler("i");
            return
        }

        const responses = [
            capitalRes,
            langRes,
            religRes,
            kingRes,
            ageRes,
            headGouvRes,
            parlMemRes,
            nationTextRes,
            motivation
        ]

        const newCitizen = {
            name,
            familyName,
            mail,
            phoneNumber,
            birthDate,
            otherNationalities: otherNat.join("'$%$'"),
            responses: responses.join("'$%$'")
        }

        citizenshipService.submitNewCitizen(newCitizen)
        .then(() => {
            // Show success notif and clear the form
            alertService.popupHandler("s", "citizen", name);
            setName("");
            setFamilyName("");
            setPhoneNumber("");
            setBirthDate("");
            setOtherNat("");
            setCapitalRes("");
            setReligRes("");
            setKingRes("");
            setAgeRes("");
            setHeadGouvRes("");
            setParlMemRes("");
            setNationTextRes("");
            setMotivation("");

        }).catch(e => {
            // console.log(e)
            // show error notif
            alertService.popupHandler("e", "citizen");
        })
    }

    const handleInputChange = (inputType) => {
        switch (inputType) {
            case ("name"):
                return (event) => {
                    setName(event.target.value)
                }
            case ("family-name"):
                return (event) => {
                    setFamilyName(event.target.value)
                }
            case ("mail"):
                return (event) => {
                    setMail(event.target.value)
                }
            case ("phoneNumber"):
                return (event) => {
                    setPhoneNumber(event.target.value)
                }
            case ("birthDate"):
                return (event) => {
                    setBirthDate(event.target.value)
                }
            case ("otherNat"):
                return (event) => {
                    setOtherNat(event.target.value.split(","))
                }
            case ("capitalRes"):
                return (event) => {
                    setCapitalRes(event.target.value)
                }
            case ("langRes"):
                return (event) => {
                    setLangRes(event.target.value)
                }
            case ("religRes"):
                return (event) => {
                    setReligRes(event.target.value)
                }
            case ("kingRes"):
                return (event) => {
                    setKingRes(event.target.value)
                }
            case ("ageRes"):
                return (event) => {
                    setAgeRes(event.target.value)
                }
            case ("headGouvRes"):
                return (event) => {
                    setHeadGouvRes(event.target.value)
                }
            case ("parlMemRes"):
                return (event) => {
                    setParlMemRes(event.target.value)
                }
            case ("nationTextRes"):
                return (event) => {
                    setNationTextRes(event.target.value)
                }
            case ("motivation"):
                return (event) => {
                    setMotivation(event.target.value)
                }
            default:
                return
        }
    }

    return (
        <>
            <div className="citizen-middle-part">
                <form className="citizen-form" onSubmit={handleSubmit}>
                    <div className="citizen-form-input-row-title">
                        <h1>
                            Citizenship Application Form
                        </h1>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="name" name="name" placeholder="Name"
                                value={name}
                                onChange={handleInputChange("name")} />
                        </div>
                    </div>
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="familyName" name="familyName" placeholder="Family Name"
                                value={familyName}
                                onChange={handleInputChange("family-name")} />
                        </div>
                    </div>
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="mail" name="mail" placeholder="Mail"
                                value={mail}
                                onChange={handleInputChange("mail")} />
                        </div>
                    </div>
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="phoneNumber" name="phoneNumber" placeholder="Phone Number"
                                value={phoneNumber}
                                onChange={handleInputChange("phoneNumber")} />
                        </div>
                    </div>
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="birthDate" name="birthDate" placeholder="Birth Date"
                                value={birthDate}
                                onChange={handleInputChange("birthDate")} />
                        </div>
                    </div>
                    <div className="citizen-form-input-row">
                        <div className="citizen-input-wrapper">
                            <input type="text" id="otherNat" name="otherNat" placeholder="Other Nationalities"
                                value={otherNat}
                                onChange={handleInputChange("otherNat")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>Where is the capital of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="capitalRes" name="capitalRes" placeholder="Your answer"
                                value={capitalRes}
                                onChange={handleInputChange("capitalRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>What is the official language of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="langRes" name="langRes" placeholder="Your answer"
                                value={langRes}
                                onChange={handleInputChange("langRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>What is the official religion of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="religRes" name="religRes" placeholder="Your answer"
                                value={religRes}
                                onChange={handleInputChange("religRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>Who is the king of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="kingRes" name="kingRes" placeholder="Your answer"
                                value={kingRes}
                                onChange={handleInputChange("kingRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>What is the minimum age of a Sabentopian citizen ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="ageRes" name="ageRes" placeholder="Your answer"
                                value={ageRes}
                                onChange={handleInputChange("ageRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>Who is the head of gouvernement of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="headGouvRes" name="headGouvRes" placeholder="Your answer"
                                value={headGouvRes}
                                onChange={handleInputChange("headGouvRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>Who are the parliment members of Sabentopia ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="parlMemRes" name="parlMemRes" placeholder="Your answer"
                                value={parlMemRes}
                                onChange={handleInputChange("parlMemRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row question-row">
                        <h3>What is the nation's most important text ?</h3>
                        <div className="citizen-input-wrapper">
                            <input type="text" id="nationTextRes" name="nationTextRes" placeholder="Your answer"
                                value={nationTextRes}
                                onChange={handleInputChange("nationTextRes")} />
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="citizen-form-input-row-text">
                        <h3>Why do you want to be a sabentopian citizen ?</h3>
                        <textarea className="citizen-form-text-area" type="text" rows="8" cols="80" value={motivation} onChange={handleInputChange("motivation")}
                            required placeholder="Your answer">
                        </textarea>
                        <br />

                    </div>
                    <div className="citizen-form-input-row submit-btn-wrapper">
                        <input type="submit" className="citizen-submit-btn" value="submit" onClick={handleSubmit} />
                    </div>





                    {/* <div className="citizen-form-input-row-text">
                        <textarea className="citizen-form-text-area" type="text" rows="8" cols="80" value={message} onChange={handleInputChange("message")} required placeholder="Write your message"></textarea>
                        <br />
                        <div className="citizen-underline"></div>
                        
                    </div> */}
                    {/* <div className="citizen-form-input-row submit-btn-wrapper">
                        <input type="submit" className="citizen-submit-btn" value="submit" />
                    </div> */}


                </form>
                {/* <form className="form" onSubmit={handleSubmit}>
                    <h2>
                        Please answer to these questions for submitting your citizenship request
                    </h2>

                    <div className="row">
                    <label for="">Name</label>
                        <input type="text" id="name" name="name"
                            value={name}
                             />
                        
                    </div>

                </form> */}
            </div>
            <Footer />
        </>
    )
}

export default Citizenship