import "./GouvernmentComponent.css";
import Footer from "../footer/Footer";
import blason from "../../assets/blason_dark3.jpg";

const GouvernmentComponent = () => {

    return (
        <>
            <div className="middle-part-gouv">
                <div className="coat-container-gouv">
                    <img className="coat-gouv" src={blason} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default GouvernmentComponent