import "./NoPageComponent.css";
import Footer from "../footer/Footer";
import blason from "../../assets/blason_dark3.jpg";

const NoPageComponent = () => {
    return (
        <>
            <div className="middle-part-no-page">
                <div className="coat-container-no-page">
                    <img className="coat-no-page" src={blason} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default NoPageComponent