import Swal from 'sweetalert2';

const handleMessageSuccess = (category, name) => {
    const title = "Thank you";
    const icon = "success";
    let text = "";

    if (category === "contact") {
        text = `Dear ${name}, your message has been sent successfully`;
    } else if (category === "citizen") {
        text = `Dear ${name}, your application has been submitted successfully. We will contact you soon`;
    }

    return {
        title,
        text,
        icon
    }
}

const handleMessageError = () => {
    return {
        title: "Sorry",
        text: "an error occured while sending your message... Please try again later",
        icon: "error"
    }
}

const popupHandler = (code = "i", category = "contact", name = "") => {
    if (code === "s") {
        const message = handleMessageSuccess(category, name);
        popupGenerator(message.title, message.text, message.icon);
    } else if (code === "e") {
        const message = handleMessageError();
        popupGenerator(message.title, message.text, message.icon);
    } else if (code === "i") {
        popupGenerator("Please", "Be sure to fill in the forms correctly", null, 3500, false);
    }
}

const popupGenerator = (title, text, icon, timer=null, confBtn=true) => {
    const obj = {
        title,
        text
    }

    if (icon) {
        obj.icon = icon;
    }

    if (timer) {
        obj.timer = timer;
        obj.position = "top";
    }
    if (!confBtn) {
        obj.showConfirmButton = false;
    }
    Swal.fire(obj);
}

export default { popupHandler }