import "./Footer.css";
import miniFlag from "../../assets/Sabentopia.png"
import { Link } from "react-router-dom";

const Footer = () => {

    return (
        <div className="footer">
            <div className="footer-left-part">
                <div className="footer-title">
                    <Link to="/">
                        Free State of Sabentopia
                    </Link>

                </div>
                <div className="footer-mini-flag-container">
                    <img className="footer-mini-flag" src={miniFlag} />
                </div>
            </div>
        </div>
    )
}

export default Footer