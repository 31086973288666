import { Outlet } from "react-router-dom";
import './Layout.css';
import Header from "../../components/header/Header";

const Layout = () => {
    return (
        <>
            <nav className="navbar" >
                <Header />
            </nav>

            <Outlet />
        </>
    )
}

export default Layout