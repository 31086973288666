import { useState } from "react";
import Footer from "../footer/Footer";
import "./ContactComponent.css";
import messageService from "../../services/message";
import alertService from "../../services/alert";

const ContactComponent = () => {

    const [name, setName] = useState("");
    const [contactInfo, setContactInfo] = useState("");
    const [message, setMessage] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!name || !contactInfo || !message) {
            alertService.popupHandler("i");
            return
        }

        const newMessage = {
            name,
            contactInfo,
            message
        }

        messageService.submitNewMessage(newMessage)
            .then(() => {
                // Show success notif and clear the form
                alertService.popupHandler("s", "contact", name);
                setName("");
                setContactInfo("");
                setMessage("");
            }).catch(e => {
                // console.log(e)
                // show error notif
                alertService.popupHandler("e", "contact");
            })
    }

    const handleInputChange = (inputType) => {
        switch (inputType) {
            case "name":
                return (event) => {
                    // console.log(name, contactInfo, message)
                    setName(event.target.value)
                }
            case "contact-info":
                return (event) => {
                    // console.log(name, contactInfo, message)
                    setContactInfo(event.target.value)
                }
            case "message":
                return (event) => {
                    // console.log(name, contactInfo, message)
                    setMessage(event.target.value)
                }
            default:
                return
        }
    }
    return (
        <>
            <div className="contact-middle-part">
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="contact-form-input-row-title">
                        <h1>
                            Contact the Gouvernment of Sabentopia
                        </h1>
                    </div>
                    {/* <br /> */}
                    <hr />
                    <div className="contact-form-input-row">
                        <div className="contact-input-wrapper">
                            <input type="text" id="name" name="name" placeholder="Name"
                                value={name}
                                onChange={handleInputChange("name")} />
                            {/* <label for="">Name</label> */}
                        </div>
                        <div className="contact-input-wrapper">
                            <input type="text" id="contact" name="contact" placeholder="Contact information"
                                value={contactInfo}
                                onChange={handleInputChange("contact-info")} />
                            {/* <label for="">Contact information</label> */}
                        </div>
                    </div>
                    <br />
                    <hr />
                    <div className="contact-form-input-row-text">
                        <textarea className="contact-form-text-area" type="text" rows="8" cols="80" value={message} onChange={handleInputChange("message")} required placeholder="Write your message"></textarea>
                        <br />
                        <div className="contact-underline"></div>
                        {/* <label for="">Write your message</label> */}
                    </div>
                    <div className="contact-form-input-row submit-btn-wrapper">
                        <input type="submit" className="contact-submit-btn" value="submit" onClick={handleSubmit} />
                    </div>


                </form>
                {/* <form className="form"  onSubmit={handleSubmit}>
                    <h2>
                        Contact the Gouvernment
                    </h2>
                    <div className="contact-form-row">
                        <div className="contact-input-data">
                            <input type="text" id="name" name="name"
                                value={name}
                                onChange={handleInputChange("name")} />
                            <label for="">Name</label>
                        </div>
                        <div className="contact-input-data">
                            <input type="text" id="contact" name="contact"
                                value={contactInfo}
                                onChange={handleInputChange("contact-info")} />
                            <label for="">Contact information</label>
                        </div>
                    </div>
                    <div className="contact-form-row">
                        <div className="contact-input-data contact-textarea">
                            <textarea type="text" rows="8" cols="80" value={message} onChange={handleInputChange("message")} required></textarea>
                            <br />
                            <div className="contact-underline"></div>
                            <label for="">Write your message</label>
                            <br />
                            <div className="contact-form-row contact-submit-btn">
                                <div className="contact-input-data">
                                    <div className="contact-inner"></div>
                                    <input type="submit" value="submit" />
                                </div>
                            </div>
                        </div>
                    </div>
                </form> */}
            </div>
            {/* <form onSubmit={handleSubmit}>
                    <div className="input-wrapper">
                        <input type="text" id="name" className="floatLabel" name="name"
                            value={name}
                            onChange={handleInputChange("name")} />
                        <label for="name">Name</label>
                    </div>
                    <div className="input-wrapper">                        <input type="text" id="contact" className="floatLabel" name="contact"
                        value={name}
                        onChange={handleInputChange("contact-info")} />
                        <label for="contact">Contact information</label>
                    </div>
                    <div className="input-wrapper">
                        <textarea type="text" id="message" className="floatLabel" name="message"
                            value={message}
                            onChange={handleInputChange("message")} />
                        <label for="message">Your message</label>
                    </div>
                    <div className="input-wrapper">
                        <button type="submit" value="Submit" className="submit-btn">Submit</button>
                    </div>

                </form> */}
            <Footer />
        </>
    )
}

export default ContactComponent