import "./HomeComponent.css";
import flag from "../../assets/flag.png";
import Footer from "../footer/Footer";

const HomeComponent = () => {

    return (
        <>
            <div className="middle-part-home">
                <div className="main-text">
                    Seule la volonté de chaque citoyen peut déterminer les limites de son savoir
                </div>
                <div className="flag-container">
                    <img className="flag" src={flag} />
                </div>
            </div>
            <Footer />
        </>
    )
}

export default HomeComponent