import axios from "axios";

// const baseUrl = "http://localhost:5000/api/messages";
// const baseUrl = "https://sabentopia-2.onrender.com/api/messages";
const baseUrl = "api/messages";

const submitNewMessage = (newMessage) => {
    return axios.post(`${baseUrl}/submit`, newMessage).then(response => response.data);
}

export default { submitNewMessage }