import NoPageComponent from "../../components/noPage/NoPageComponent";

const NoPage = () => {
    return (
        <>
            <NoPageComponent />
        </>
    )
}

export default NoPage