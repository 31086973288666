import "./Header.css";
import miniFlag from "../../assets/Sabentopia.png"
import { Link } from "react-router-dom";

const Header = () => {

    return (
        <>
            <div className="header-left-part">
                <div className="header-title">
                    <Link to="/">
                        Free State of Sabentopia
                    </Link>

                </div>
                <div className="header-mini-flag-container">
                    <img className="header-mini-flag" src={miniFlag} />
                </div>
            </div>
            <div className="header-right-part">
                <div className="header-btn-part">
                    <Link className="header-nav-link" to="constitution"><span className="header-link-title">Constitution</span></Link>
                    <Link className="header-nav-link" to="gouvernment"><span className="header-link-title">Gouvernment</span></Link>
                    <Link className="header-nav-link" to="citizenship"><span className="header-link-title">Citizenship</span></Link>
                    <Link className="header-nav-link" to="contact"><span className="header-link-title">Contact</span></Link>
                </div>
            </div>
        </>
    )
}

export default Header