import Footer from "../footer/Footer";
import "./ConstitutionComponent.css";

const ConstitutionComponent = () => {
    return (
        <>
            <div className="consti-middle-part">
                <div className="top-part">
                    <h1>
                        Free State of Sabentopia
                    </h1>
                    <br />

                    <h2>
                        Constitution
                    </h2>
                </div>
                <br />
                <hr />
                <div className="consti-preambule-wrapper">
                    <p>
                        Nous, les êtres humains libres du monde, nous réunissons autour de ce texte afin de fonder une
                        nouvelle nation. Ce qui prime dans cette nation est le droit universel de savoir. Nous proclamons
                        notre attachement à l’accessibilité sans condition de savoir et les moyens d’y atteindre.
                    </p>
                    <br />
                    <p>
                        L’éducation doit devenir, être et rester gratuite et à la portée de tout le monde. Seule la volonté
                        de chaque citoyen peut déterminer les limites de son savoir. Cette nouvelle nation porte le nom de «
                        The Free State of Sabentopia ». L’abréviation « FSS » peut être utilisé quand la nécessité se
                        présente.

                    </p>
                </div>
                <div className="consti-list-wrapper">
                    <ul className="consti-list">
                        <li className="consti-list-item">
                            <span className="article-title">Article I. La citoyenneté</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p> La citoyenneté de cette nation est un droit ainsi qu’un devoir. Tous les citoyens
                                        sont
                                        égaux dans ce qui concerne la citoyenneté. Un citoyen ne doit pas être le sujet
                                        d’une
                                        discrimination, de toute forme, à cause des éléments suivants : sexe, âge, lieu de
                                        naissance, langue, tendances politiques, caractéristiques physiques, handicap,
                                        régime
                                        alimentaire, orientations sexuelles, liens amicaux ou familiaux.
                                        La citoyenneté est un droit confié à chaque être humain qui respecte l’intégralité
                                        du
                                        texte présent et qui suit les démarches nécessaires afin d’obtenir la citoyenneté.
                                        La citoyenneté est un devoir. Le non-respect de certaines conditions peut causer la
                                        révocation de la citoyenneté. </p>
                                    <p>
                                        Les conditions principales à respecter sont les suivantes :
                                        <ul className="consti-list">
                                            <li className="consti-list-item">
                                                - L’acceptation intégrale de texte présent
                                            </li>
                                            <li className="consti-list-item">
                                                - Le respect des droits des autres citoyens
                                            </li>
                                            <li className="consti-list-item">
                                                - Le respect de la laïcité
                                            </li>
                                            <li className="consti-list-item">
                                                - Le respect envers Sabentopia et accepter son existence
                                            </li>
                                        </ul>

                                    </p>
                                    <p>
                                        Il est impossible d’hériter la citoyenneté. Chaque individu intéressé peut entamer
                                        les
                                        processus nécessaires afin d’obtenir la citoyenneté à partir de l’âge de 15 ans et 2
                                        mois.
                                    </p>


                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article II. Les élections</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        Dans certains cas et afin de prendre certaines décisions, il peut y avoir des
                                        élections. Le gouvernement est le responsable de mener ces élections. Les élections
                                        sont possibles uniquement sous l’autorisation royale de sa majesté, le Roy de
                                        Sabentopia. Les résultats des élections sont valables uniquement après avoir obtenu
                                        l’avis positif et royal de sa majesté, le Roy de Sabentopia.
                                    </p>
                                    <p>
                                        Tous les citoyens ont le droit de voter à condition d’avoir plus de 18 ans le jour
                                        des élections.
                                    </p>
                                    <p>
                                        Le vote de sa majesté, le Roy de Sabentopia compte nul et ne peut pas être considéré
                                        dans le comptage des votes. Son vote royal a une valeur totalement symbolique.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article III. Le gouvernement</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        The Free State of Sabentopia est une monarchie constitutionnelle. Sa majesté, le Roy
                                        de Sabentopia est à la tête du gouvernement. Une personne est désignée comme le
                                        premier ministre par sa majesté, le Roy de Sabentopia. Le premier ministre a la
                                        responsabilité de former le gouvernement. Les rôles principaux de gouvernement et le
                                        cabinet proposé par le premier ministre doivent être validé par la majorité des
                                        membres du parlement.
                                    </p>
                                    <p>
                                        Le parlement de Sabentopia est constitué de tous les citoyens. Chaque citoyen a le
                                        droit de voter ainsi que candidater pour le gouvernement.
                                    </p>
                                    <p>
                                        Le rôle de premier ministre n’est pas sujet de vote. C’est sa majesté, le Roy de
                                        Sabentopia qui désigne le premier ministre. Ce dernier a la responsabilité de
                                        proposer la structure des ministères pour former un gouvernement. Les ministères
                                        deviennent officiels après la validation du parlement.
                                    </p>
                                    <p>
                                        La proposition de la structure des ministères se fait systématiquement tous les 6
                                        mois. Le premier ministre peut valider, au cas échéant, la même structure
                                        ministériale sans aucun changement. Dans ce cas, le gouvernement peut continuer à
                                        fonctionner sans avoir besoin du vote du parlement. Dans le cas où aucun changement
                                        ministérial n’est proposé pendant 1 an, le parlement doit valider la structure.
                                    </p>
                                    <p>
                                        Par son pouvoir majestueux, sa majesté le Roy de Sabentopia peut proposer au premier
                                        ministre, dans la limite de 6 fois par an, l’ajout ou l’annulation des ministères.
                                        Les propositions deviennent officielles après la validation du parlement.
                                    </p>
                                    <p>
                                        Dans un délai de 14 jours après la validation des ministères par le gouvernement,
                                        tous les citoyens peuvent se présenter pour les postes dans chaque ministère. Chaque
                                        citoyen peut candidater uniquement pour un poste. A la fin de ce délai de 14 jours,
                                        le parlement doit voter pour les candidats.
                                    </p>
                                    <p>
                                        Toute décision du parlement devient officielle uniquement après une validation par
                                        sa majesté, le Roy de Sabentopia.
                                    </p>
                                    <p>
                                        Dans le cas de non validation d’une décision parlementaire par sa majesté, le Roy de
                                        Sabentopia, le parlement doit prendre une nouvelle décision.
                                    </p>
                                    <p>
                                        Sa majesté, le Roy de Sabentopia ne possède pas le droit de dissolution du
                                        parlement.
                                    </p>
                                    <p>
                                        Les réunions de parlement son officielles à condition de présence d’au moins 5
                                        citoyens. A la fin de chaque réunion, un rapport est à rendre au premier ministre.
                                        Sans ce rapport, la réunion n’est pas considérée comme officielle. Les décision
                                        prises lors des réunions non officielles du parlement n’ont aucune valeur
                                        officielle.
                                    </p>
                                    <p>
                                        Le parlement peut à tout moment demander à sa majesté, le Roy de Sabentopia la
                                        dissolution du gouvernement. Sa majesté, le Roy de Sabentopia a l’unique pouvoir de
                                        décider cette dissolution. Après la dissolution, le premier ministre aura 14 jours
                                        pour proposer un nouveau gouvernement. Dans le cas des changements dans la structure
                                        ministériale, ces changements doivent être approuvés par le parlement avant de
                                        commencer la période des candidatures.
                                    </p>
                                    <p>
                                        Sa majesté, le Roy de Sabentopia a le pouvoir de dissolution du gouvernement à tout
                                        moment sans avoir besoin de validation parlementaire. Le premier ministre se
                                        chargera alors de former un nouveau gouvernement.
                                    </p>
                                    <p>
                                        Sa majesté, le Roy de Sabentopia a le pouvoir de changer le premier ministre à tout
                                        moment sans avoir besoin de validation parlementaire. Le changement de premier
                                        ministre n’entraine pas automatiquement la dissolution du gouvernement.
                                    </p>
                                    <p>

                                    </p>
                                </li>

                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIII. Les droits de citoyen</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        Ce texte garantit à tous les citoyens de Sabentopia, le droit de liberté
                                        d’expression. Personne ne peut se voir discriminé, attaqué ou limité à cause de ses
                                        pensées, ses opinions ou ses paroles.
                                    </p>
                                    <p>
                                        The Free State of Sabentopia ne reconnaît pas la propriété privée concernant la
                                        terre. Tout le territoire de FSS est national et appartient à la nation.
                                    </p>
                                    <p>
                                        The Free State of Sabentopia est une nation absolument laïque. Sa majesté, le Roy de
                                        Sabentopia surveille personnellement la laïcité au sein de gouvernement et
                                        concernant les décisions de parlement. Le non-respect de la laïcité de la part d’un
                                        citoyen peut avoir comme conséquence la révocation de sa citoyennetépar le décret
                                        majestueux de sa majesté, le Roy de Sabentopia.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIIII. La langue</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        The Free State of Sabentopia n’a pas de langue officielle. Chaque citoyen est libre
                                        de communiquer avec le gouvernement et les autres citoyens en toute langue. Le
                                        gouvernement n’est pas contraint à communiquer avec les citoyens en une langue
                                        précise. La langue de fonctionnement du gouvernement et des documents officiels peut
                                        varier, mais les citoyens sont libres de communiquer en toute langue.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIIIII. Le système judiciaire</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        Le système judiciaire de FSS est constitué de tous les citoyens. Au besoin, un
                                        parlement judiciaire national se forme afin d’étudier un cas et prendre une
                                        décision. La décision finale doit être approuvée par sa majesté, le Roy de
                                        Sabentopia afin de devenir valide. Les citoyens impliqués dans un cas judiciaire ne
                                        peuvent pas participer au parlement judiciaire national que pour répondre aux
                                        questions.
                                    </p>
                                    <p>
                                        Le parlement judiciaire national se forme suite à la demande d’un ou plusieurs
                                        citoyens auprès du premier ministre.
                                    </p>
                                    <p>
                                        Les décisions du parlement judiciaire national doivent respecter l’intégralité du
                                        texte présent.
                                    </p>
                                    <p>
                                        La peine ultime qui peut éventuellement être décidé par le parlement judiciaire
                                        national est la révocation de la citoyenneté.
                                    </p>
                                    <p>
                                        Dans le cas de non validation d’une décision parlementaire par sa majesté, le Roy de
                                        Sabentopia, le parlement doit prendre une nouvelle décision.
                                    </p>
                                    <p>
                                        Sa majesté, le Roy de Sabentopia ne peut pas être le sujet d’un cas judiciaire et a
                                        le droit de priver certains citoyens de faire partie du parlement judiciaire
                                        national.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIIIIII. La constitution</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        La constitution de FSS est le texte le plus important de la nation. Aucune décision
                                        ne peut être prise en contradiction avec l’intégralité du texte présent.
                                    </p>
                                    <p>
                                        Le texte présent ne peut changer qu’avec l’initiative de sa majesté, le Roy de
                                        Sabentopia. Chaque changement proposé par sa majesté, le Roy de Sabentopia doit être
                                        approuvée par le parlement. Le changement devient officiel après la validation de la
                                        majorité des citoyens via un référendum surveillé par sa majesté, le Roy de
                                        Sabentopia.
                                    </p>
                                    <p>
                                        Le parlement peut proposer un changement dans la constitution au premier ministre.
                                        Le changement doit être proposé par au moins 5 citoyens. Après la validation, le
                                        premier ministre peut proposer le changement à sa majesté, le Roy de Sabentopia.
                                        Cette proposition doit être validé par sa majesté, le Roy de Sabentopia et sera le
                                        sujet d’un référendum surveillé par sa majesté. Le changement devient officiel après
                                        la validation de la majorité des citoyens.
                                    </p>
                                    <p>
                                        Sa majesté, le Roy de Sabentopia peut à tout moment annuler l’intégralité des
                                        changements pour revenir au texte initial de la constitution. L’annulation des
                                        changements n’est possible qu’en intégralité. Cette décision majestueuse n’a pas
                                        besoin d’être approuvée par le parlement.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIIIIIII. Le savoir</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        Le savoir doit être gratuitement accessible à tous les citoyens, sans aucune
                                        condition. Chacun a le droit et la liberté d’acquérir le savoir dans n’importe quel
                                        domaine souhaité. L’éducation doit être gratuite et financée par l’ensemble des
                                        citoyens. Le budget du gouvernement est destiné prioritairement pour l'éducation
                                        accessible à tous les citoyens.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <br />
                        <li className="consti-list-item">
                            <span className="article-title">Article IIIIIIIII. Le territoire</span>
                            <ul className="consti-list">
                                <br />
                                <hr />
                                <li className="consti-list-item">
                                    <p>
                                        The Free State of Sabentopia réclame le lac situé à 44°39'35.0"N 2°45'40.0"E comme son territoire. Au sein de ce
                                        territoire, les citoyens de FSS ont toute leurs droits garantis par leur
                                        citoyenneté.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default ConstitutionComponent